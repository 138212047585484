import { EqualFn } from '@fmnts/core';
import moment from 'moment';
import { DateCompareOptions } from './chronos.type';

/**
 * Higher order function for creating a function
 * that compares two dates for their equality
 * with a given granularity.
 *
 * @param granularity Smallest unit of time to consider for equality.
 *
 * @returns
 * Value equality function that returns `true` if the two dates
 * are equal for the given granularity.
 */
export function equalDate(
  granularity: moment.unitOfTime.StartOf,
): EqualFn<Date> {
  return (date: Date, other: Date) => moment(date).isSame(other, granularity);
}

/**
 * @param date Date that should be checked
 * @returns
 * `true` if the Date equals today,
 * `false` otherwise
 */
export function isToday(date: Date): boolean {
  return isSameDay(date, new Date());
}

/**
 * @param date Date
 * @param other Other date
 * @returns
 * `true` when `date` and the `other` have the same year, month
 * and day.
 */
export const isSameDay = equalDate('day');

/**
 * @param other Date, after which the passed value should come
 * @param opts Optional options to change the behaviour
 *
 * @returns
 * A function that can be called with a date.
 * This function returns `true`, if the value comes
 * chronologically **after** `other`.
 *
 * @example
 * const afterCheck = isAfter('1999-12-31');
 * afterCheck('2000-01-01') // true
 * afterCheck('1999-12-30') // false
 *
 *
 * @see {@link https://momentjs.com/docs/#/query/is-after/ }
 */
export function isAfter(
  other: moment.MomentInput,
  opts: DateCompareOptions = {},
): (value: moment.MomentInput) => boolean {
  const { exclusive, granularity } = opts;
  const minDate = moment(other, true);

  return (value) => {
    const date = moment(value, true);

    return exclusive
      ? date.isAfter(minDate, granularity)
      : date.isSameOrAfter(minDate, granularity);
  };
}

/**
 * @param other Date, before which the passed value should come
 * @param opts Optional options to change the checks behaviour
 *
 * @returns
 * A function that can be called with a date.
 * This function returns `true`, if the value comes
 * chronologically **before** `other`.
 *
 * @example
 * const beforeCheck = isBefore('1999-12-31');
 * beforeCheck('1999-12-30') // true
 * beforeCheck('2000-01-01') // false
 *
 *
 * @see {@link https://momentjs.com/docs/#/query/is-before/ }
 */
export function isBefore(
  other: moment.MomentInput,
  opts: DateCompareOptions = {},
): (value: moment.MomentInput) => boolean {
  const { exclusive, granularity } = opts;
  const maxDate = moment(other, true);

  return (value) => {
    const date = moment(value, true);

    return exclusive
      ? date.isBefore(maxDate, granularity)
      : date.isSameOrBefore(maxDate, granularity);
  };
}
