/**
 * @param obj Value to check
 *
 * @returns
 * `true`, if the given `obj` is a record, meaning that it is safe
 * to perform operators like `'something' in obj` or `obj['some_property']` on it.
 *
 * @example
 * isRecord({}); // true
 * isRecord([]); // true
 * isRecord({prop: 'value'}); // true
 * isRecord(['1', '2']); // true
 *
 * @example
 * isRecord(undefined); // false
 * isRecord(null); // false
 */
export function isRecord(
  obj: unknown,
): obj is Record<string | number, unknown> {
  return obj !== null && typeof obj === 'object';
}
