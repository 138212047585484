import { NgModule } from '@angular/core';
import { I18nCurrencyPipe } from './currency.pipe';
import { I18nDecimalPipe } from './decimal.pipe';
import { I18nDurationPipe } from './duration.pipe';
import { TranslateBooleanPipe } from './translate-boolean.pipe';
import { TranslateInlinePipe } from './translate-inline.pipe';
import { TranslateWithFallbackPipe } from './translate-with-fallback.pipe';
import { TranslatePipe } from './translate.pipe';

const DECLARATIONS = [
  TranslateBooleanPipe,
  TranslateInlinePipe,
  TranslatePipe,
  TranslateWithFallbackPipe,
  I18nCurrencyPipe,
  I18nDecimalPipe,
  I18nDurationPipe,
];

/**
 * Module for internationalization (i18n).
 * Use this module when you want to use the i18n features in a
 * component / module.
 */
@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class I18nModule {}
