export interface Vector2dLike {
  /** x component */
  x: number;
  /** y component */
  y: number;
}

/**
 * Immutable 2-dimensional vector.
 */
export class Vector2d implements Vector2dLike {
  /** Vector (0, 0). */
  public static readonly zero = new Vector2d(0, 0);
  /** Vector (1, 1). */
  public static readonly one = new Vector2d(1, 1);
  /** Vector (0, 1) pointing up. */
  public static readonly up = new Vector2d(0, 1);
  /** Vector (0, -1) pointing down. */
  public static readonly down = new Vector2d(0, -1);
  /** Vector (-1, 0) pointing left. */
  public static readonly left = new Vector2d(-1, 0);
  /** Vector (1, 0) pointing right. */
  public static readonly right = new Vector2d(1, 0);

  /**
   * @returns
   * The length of the vector, squared.
   */
  get sqrtMagniture(): number {
    return Vector2d.dot(this, this);
  }

  /**
   * @returns
   * The length of the vector
   */
  get magnitude(): number {
    return Math.sqrt(this.sqrtMagniture);
  }

  constructor(
    public readonly x: number,
    public readonly y: number,
  ) {}

  /**
   * Creates a clone from a vector-ish input.
   *
   * @param components Vector like structure to clone from
   *
   * @returns
   * An instance of Vector2d from the components.
   */
  static clone(components: Vector2dLike): Vector2d {
    return new Vector2d(components.x, components.y);
  }

  /**
   * Calculates the dot product between 2 vectors `a` and `b`
   * defined as `|a| |b| cos(phi)`.
   *
   * @param a Vector
   * @param b Vector
   *
   * @returns
   * Dot product of `a` and `b`, which is the same as the cosin
   * of the angle between the two vectors.
   */
  static dot(a: Vector2d, b: Vector2d): number {
    return a.x * b.x + a.y * b.y;
  }

  /**
   * Calculates the distance between 2 vectors `a` and `b`.
   *
   * @param a Vector
   * @param b Vector
   *
   * @returns
   * Distance between both vectors `a` and `b`.
   */
  static distance(a: Vector2d, b: Vector2d): number {
    return a.subtract(b).magnitude;
  }

  /**
   * Returns the smaller values of `vectors` for both `x` and `y`
   *
   * @param vectors
   * @returns
   * New vector, containing min values
   */
  static min(...vectors: Vector2d[]): Vector2d {
    return vectors.reduce(
      (acc, cur) =>
        new Vector2d(
          acc.x <= cur.x ? acc.x : cur.x,
          acc.y <= cur.y ? acc.y : cur.y,
        ),
    );
  }

  /**
   * Returns the larger values of `vectors` for both `x` and `y`
   *
   * @param vectors
   * @returns
   * New vector, containing max values
   */
  static max(...vectors: Vector2d[]): Vector2d {
    return vectors.reduce(
      (acc, cur) =>
        new Vector2d(
          acc.x >= cur.x ? acc.x : cur.x,
          acc.y >= cur.y ? acc.y : cur.y,
        ),
    );
  }

  /**
   * Adds the `other` vectors to `this`.
   *
   * @param other Other vectors
   *
   * @returns
   * New vector that is the sum between `this` and all the `other`s.
   */
  add(...other: Vector2d[]): Vector2d {
    return other.reduce(
      (acc, cur) => new Vector2d(acc.x + cur.x, acc.y + cur.y),
      this,
    );
  }

  /**
   * Subtracts the `other` vectors from `this`.
   *
   * @param other Other vectors
   *
   * @returns
   * New Vector that is the difference of the given vectors.
   */
  subtract(...other: Vector2d[]): Vector2d {
    return this.add(...other.map((o) => o.invert()));
  }

  /**
   * Scales each component of a vector by the given `factor`.
   *
   * @param factor Scale factor
   *
   * @returns
   * New vector with each component scaled.
   */
  scale(factor: number): Vector2d {
    return new Vector2d(this.x * factor, this.y * factor);
  }

  /**
   * @returns
   * A vector with each component inverted.
   */
  invert(): Vector2d {
    return this.scale(-1);
  }

  /**
   * @returns
   * Representation as array
   */
  toArray(): [number, number] {
    return [this.x, this.y];
  }
}
