import type { Adapter } from '@fmnts/core';
import { includedIn } from '@fmnts/core/collection';
import { IApiCustomer } from './api-model';
import { Customer } from './customer';
import { CustomerFeature } from './domain-enums';

/**
 * Type guard that checks if a given string is a value of one of
 * the known `Feature`s.
 */
const isFeature = includedIn<string, CustomerFeature>(
  Object.values(CustomerFeature),
);

export class ApiCustomerAdapter implements Adapter<Customer> {
  public adapt(apiDto: IApiCustomer): Customer {
    return {
      id: apiDto.id,
      currency: apiDto.currency,
      currencyCode: apiDto.currency_code,
      features: apiDto.features.filter(isFeature),
      locale: apiDto.locale,
      name: apiDto.name,
      pointsLabel: apiDto.points_label,
      slug: apiDto.slug,
    };
  }
}
