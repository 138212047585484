import { isString } from '@fmnts/core';
import { createIntegerCoercion } from '@fmnts/core/math';
import { isDate } from './date.type-guard';
import { parseISO } from './iso-8601-format';

/**
 * Coerce string input for days
 */
export const coerceDayInput = createIntegerCoercion({ max: 31 });

/**
 * Coerce string input for months
 */
export const coerceMonthInput = createIntegerCoercion({ max: 12 });

/**
 * Coerces a date(-like) `value` to a date.
 *
 * @param value Value as date type or ISO formatted string.
 * @returns
 * Value as Date. If the value can't be coerced, an invalid date
 * is returned.
 *
 * @example
 * const date = coerceIsoDate('2020-01-01');
 *
 * @example
 * const input = 'some invalid input';
 * const result = coerceIsoDate(input);
 * if (isInvalidDate(result)) {
 *  throw new Error('not a valid date ' + input);
 * }
 */
export function coerceIsoDate(value: unknown): Date {
  return isDate(value)
    ? value
    : isString(value)
      ? parseISO(value)
      : new Date(NaN);
}
