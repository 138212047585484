import { Duration } from './chronos.type';
import { comparePeriodsDesc, Period, reduceByPeriods } from './periods';

interface DurationFormatterOptions {
  /**
   * Specifies the format to display the passed durations in.
   * Supported values are:
   * - `h`: Hours without leading zero
   * - `hh`: Hours with leading zero
   * - `m`: Minutes without leading zero
   * - `mm`: Minutes with leading zero
   * - `s`: Seconds without leading zero
   * - `ss`: Seconds with leading zero
   *
   * @example
   * 'hh:mm:ss'
   *
   * @example
   * 'hh:mm'
   */
  format: string;
}

/**
 * Regex for extracting time formatting tokens.
 */
const REGEX_FORMAT_TOKENS = /h{1,2}|m{1,2}|s{1,2}/g;

/**
 * Creates an instance for formatting durations.
 *
 * @see {@link DurationFormatterOptions}
 */
export class DurationFormatter {
  /** Periods that are relevant for applying the format */
  private readonly relevantPeriods: readonly Period[];
  /** Holds the desired format */
  private readonly _format: string;

  constructor(opts: DurationFormatterOptions) {
    const { format } = opts;

    // Find the used tokens in the format and map to periods
    const periods: Period[] = [];
    for (const match of format.match(REGEX_FORMAT_TOKENS) ?? []) {
      switch (match) {
        case 'h':
        case 'hh':
          periods.push('hours');
          break;
        case 'm':
        case 'mm':
          periods.push('minutes');
          break;
        case 's':
        case 'ss':
          periods.push('seconds');
          break;
      }
    }

    this._format = format;
    this.relevantPeriods = periods.sort(comparePeriodsDesc);
  }

  /**
   * Formats the given `duration` based on the format for
   * that this formatter is configured for.
   *
   * @param duration Duration
   *
   * @returns
   * Duration as a formatted string.
   */
  format(duration: Duration): string {
    const d = reduceByPeriods(duration, this.relevantPeriods);

    const hours = `${Math.floor(d.hours ?? 0)}`;
    const minutes = `${Math.floor(d.minutes ?? 0)}`;
    const seconds = `${Math.floor(d.seconds ?? 0)}`;

    // Make sure that all possible match tokens from the regex
    // `REGEX_FORMAT_TOKENS` are defined here as well.
    const matches = {
      h: hours,
      hh: hours.padStart(2, '0'),
      m: minutes,
      mm: minutes.padStart(2, '0'),
      s: seconds,
      ss: seconds.padStart(2, '0'),
    };

    return this._format.replace(
      REGEX_FORMAT_TOKENS,
      (match) => matches[match as keyof typeof matches],
    );
  }
}
