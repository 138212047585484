import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import type { Subscription } from 'rxjs';
import { TranslationService } from './translation.service';
import type { InlineTranslateable } from './translation.types';

/**
 * Pipe that extracts the localization from a passed object
 * based on the current language(s).
 */
@Pipe({
  name: 'translateInline',
  pure: false,
})
export class TranslateInlinePipe implements PipeTransform, OnDestroy {
  private subscription: Subscription;

  constructor(
    private translationService: TranslationService,
    private cd: ChangeDetectorRef,
  ) {
    // Update components with OnPush change detection strategy
    this.subscription = this.translationService.currentLanguage$.subscribe(
      () => {
        this.cd.markForCheck();
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  transform(data: InlineTranslateable): string {
    return this.translationService.getInlineTranslation(data);
  }
}
