/**
 * Creates a function for coercing some integer-like input.
 *
 * @param opts Options
 *
 * @returns
 * Function for coercing some number-like string input to a certain
 * format specified by the given `opts`.
 */
export function createIntegerCoercion(opts: {
  /**
   * Maximum possible number (inclusive).
   * Inputs that are bigger, will be clamped to this value.
   * Inputs that are bigger
   */
  max: number;
}): (input: string) => string {
  const { max } = opts;
  const maxAsString = max.toString();
  const maxLength = maxAsString.length;
  /**
   * This is the maximum possible leading number. Inputs bigger then
   * this are padded with leading zeros.
   *
   * So e.g. if `max=234` this will be `23`. If the given input is
   * `24`, it will be transformed to `024`, because due to max it
   * can't be >= 240
   */
  const maxLeading = parseInt(maxAsString.substring(0, maxLength - 1), 10);

  return (input) => {
    // trim everything that exceeds maxLength
    const part = maxLength ? input.substring(0, maxLength) : input;
    const asNumber = parseInt(part, 10);

    if (asNumber > max) {
      return maxAsString;
    } else if (part.length < maxLength && asNumber > maxLeading) {
      return part.padStart(maxLength, '0');
    }

    // Everything else should be unchanged
    return part;
  };
}
