import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { TranslationService } from './translation.service';
import type { Subscription } from 'rxjs';
import type { TranslationId } from './translation.types';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform, OnDestroy {
  private subscription: Subscription;

  constructor(
    private translationService: TranslationService,
    private cd: ChangeDetectorRef,
  ) {
    // Update components with OnPush change detection strategy
    this.subscription = this.translationService.currentLanguage$.subscribe(
      () => {
        this.cd.markForCheck();
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  transform(value: TranslationId, ...substitutions: unknown[]): string {
    return this.translationService.getTranslation(value, ...substitutions);
  }
}
