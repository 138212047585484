import auth from './auth.hu.json';
import cockpit from './cockpit.hu.json';
import common from './common.hu.json';
import next from './next.hu.json';
import verify from './verify.hu.json';

export default {
  ...auth,
  ...cockpit,
  ...common,
  ...next,
  ...verify,
};
