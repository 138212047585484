import { AnimationEvent } from '@angular/animations';
import { MonoTypeOperatorFunction, filter } from 'rxjs';

/**
 * Filters emissions of animation events of a particular animation phase
 *
 * @param phase Phase of the event
 * @param predicate Optional predicate to narrow down animation events
 *
 * @returns
 */
export function filterAnimationPhaseEvent(
  phase: 'start' | 'done',
  predicate?: (e: AnimationEvent) => boolean,
): MonoTypeOperatorFunction<AnimationEvent> {
  return predicate
    ? filter((e) => e.phaseName === phase && predicate(e))
    : filter((e) => e.phaseName === phase);
}
