import auth from './auth.lb.json';
import cockpit from './cockpit.lb.json';
import common from './common.lb.json';
import next from './next.lb.json';
import verify from './verify.lb.json';

export default {
  ...auth,
  ...cockpit,
  ...common,
  ...next,
  ...verify,
};
