import {
  Adapter,
  isDefined,
  isNil,
  PrimitiveNullUndefined,
  PrimitiveValue,
  TemplateStringable,
} from '@fmnts/core';
import {
  IApiDate,
  IApiDatetime,
  IApiEntityId,
  IApiGeoCoordinates,
  IApiNamedEntityRef,
} from './model/api-model';
import { GeoCoordinates } from './model/shared-api.types';

export class ApiDateAdapter implements Adapter<Date> {
  adapt(apiDto: IApiDate): Date {
    return new Date(apiDto);
  }
  /**
   * Adapts a date for sending to the API.
   * This should only be used for dates, not timezone-aware timestamp-like data,
   *
   * @param date
   * @returns
   * Date represented as `YYYY-MM-DD`
   */
  adaptToApi(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
}

export class ApiDatetimeAdapter implements Adapter<Date> {
  adapt(apiDto: IApiDatetime): Date {
    return new Date(apiDto);
  }
  adaptToApi(date: Date): string {
    return date.toISOString();
  }
}

export class ApiGeoCoordinatesAdapter implements Adapter<GeoCoordinates> {
  adapt(value: IApiGeoCoordinates): GeoCoordinates {
    return {
      longitude: parseFloat(value.longitude),
      latitude: parseFloat(value.latitude),
    };
  }
}

export class ApiNamedEntityRefAdapter
  implements Adapter<Readonly<{ id: string; label: string }>>
{
  adapt(apiDto: IApiNamedEntityRef): {
    readonly id: string;
    readonly label: string;
  } {
    return {
      id: `${apiDto.key}`,
      label: apiDto.label,
    };
  }
}

export class AdaptOrNull {
  valueOrNull<T>(value: T | null | undefined): T | null {
    return isNil(value) ? null : value;
  }

  adaptOrNull<T, TSource>(
    dto: TSource | null | undefined,
    adapter: Adapter<T, TSource>,
  ): T | null {
    return isNil(dto) ? null : adapter.adapt(dto);
  }
}

export class ApiArrayAdapter {
  adaptItemsOrEmpty<T, TSource>(
    items: readonly Readonly<TSource>[] | null | undefined,
    itemAdapter: Adapter<T, Readonly<TSource>>,
  ): T[] {
    return (items ?? []).map((i) => itemAdapter.adapt(i));
  }

  adaptItemsOrNull<T, TSource>(
    items: TSource[] | null | undefined,
    itemAdapter: Adapter<T, TSource>,
  ): T[] | null {
    return isNil(items) ? null : items.map((i) => itemAdapter.adapt(i));
  }

  itemsOrNull<T extends ReadonlyArray<any>>(
    items: T | null | undefined,
  ): T | null {
    return isNil(items) ? null : items;
  }
}

export class ToApiDtoAdapter {
  adaptMaybeReference(item: Readonly<{ id: string }> | null): string | null {
    return isNil(item) ? null : item.id;
  }
  adaptReference<T extends IApiEntityId>(item: Readonly<{ id: T }>): T {
    return item.id;
  }
  adaptReferences<T extends IApiEntityId>(
    items: readonly Readonly<{ id: T }>[],
  ): T[] {
    return items.map((i) => this.adaptReference(i));
  }

  adaptToJson(
    obj: Record<string, PrimitiveNullUndefined | PrimitiveValue[]>,
  ): Record<string, PrimitiveValue | PrimitiveValue[]> {
    const result: Record<string, PrimitiveValue | PrimitiveValue[]> = {};

    for (const [key, value] of Object.entries(obj)) {
      if (!isNil(value)) {
        result[key] = value;
      }
    }

    return result;
  }

  /**
   * @deprecated
   * use {@link FormDataAdapter}
   */
  adaptToFormData(
    obj: Record<string, TemplateStringable | TemplateStringable[]>,
  ): FormData {
    const data = new FormData();

    for (const key in obj) {
      if (!isNil(obj[key])) {
        const value = obj[key];
        if (Array.isArray(value)) {
          data.append(key, value.join(','));
        } else {
          data.append(key, `${value}`);
        }
      }
    }

    return data;
  }
}

/** Type that can be transformed and appended to a `FormData`. */
type TransformableFormDataValue =
  | TemplateStringable
  | TemplateStringable[]
  | File
  | null;

/**
 * Helper type to ensure that a given type can be safely
 * converted into a `FormData` object.
 */
type FormDataTransformable<T> = T extends {
  [P in keyof T]: TransformableFormDataValue;
}
  ? T
  : never;

/**
 * Adapter for transforming JS values into `FormData`.
 */
export class FormDataAdapter {
  /**
   * Creates a `FormData` instance by taking each property in the
   * given `record` and appends a the key,value pair for this property.
   *
   * @param record
   * @returns
   * A `FormData` instance.
   */
  adaptRecord<T>(record: T & FormDataTransformable<T>): FormData;
  adaptRecord(record: Record<string, TransformableFormDataValue>): FormData;
  adaptRecord(record: Record<string, TransformableFormDataValue>): FormData {
    return this.appendProperties(new FormData(), record);
  }

  appendEntries<T extends TransformableFormDataValue>(
    formData: FormData,
    entries: Iterable<readonly [string, T]>,
  ): FormData;
  appendEntries(
    formData: FormData,
    entries: Iterable<readonly [string, TransformableFormDataValue]>,
  ): FormData;
  appendEntries(
    formData: FormData,
    entries: Iterable<readonly [string, TransformableFormDataValue]>,
  ): FormData {
    for (const [key, value] of entries) {
      if (isDefined(value)) {
        if (value instanceof File) {
          this.appendFile(formData, key, value);
        } else if (Array.isArray(value)) {
          this.appendArray(formData, key, value);
        } else {
          this.appendPrimitive(formData, key, value);
        }
      }
    }

    return formData;
  }

  appendProperties(
    formData: FormData,
    record: Record<string, TransformableFormDataValue>,
  ): FormData {
    return this.appendEntries(formData, Object.entries(record));
  }

  appendArray(
    formData: FormData,
    name: string,
    values: TemplateStringable[],
  ): FormData {
    values.forEach((item) => {
      formData.append(name, this.adaptPrimitiveValue(item));
    });

    return formData;
  }

  appendPrimitive(
    formData: FormData,
    name: string,
    value: TemplateStringable | null,
  ): FormData {
    formData.set(name, this.adaptPrimitiveValue(value));
    return formData;
  }

  appendJson(
    formData: FormData,
    name: string,
    value: Record<any, any>,
  ): FormData {
    formData.set(name, JSON.stringify(value));
    return formData;
  }

  appendFile(
    formData: FormData,
    name: string,
    file: File | null | undefined,
  ): FormData {
    if (file) {
      formData.set(name, file);
    } else if (file === null) {
      formData.set(name, this.adaptPrimitiveValue(null));
    }

    return formData;
  }

  adaptPrimitiveValue(value: TemplateStringable | null): string {
    if (value === null) {
      // null values should be sent as empty strings
      return '';
    }

    return `${value}`;
  }
}
