import auth from './auth.en.json';
import cockpit from './cockpit.en.json';
import common from './common.en.json';
import next from './next.en.json';
import verify from './verify.en.json';

export default {
  ...auth,
  ...cockpit,
  ...common,
  ...next,
  ...verify,
};
