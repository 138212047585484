import auth from './auth.de.json';
import cockpit from './cockpit.de.json';
import common from './common.de.json';
import next from './next.de.json';
import verify from './verify.de.json';

export default {
  ...auth,
  ...cockpit,
  ...common,
  ...next,
  ...verify,
};
