import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/**
 * Module that provides the fontawesome icon components.
 *
 * This module provides only the components and providers for
 * using the icons. To actually make icons available either
 * - use the `FaIconLibrary`
 * - use `FmntsDefaultIconsLib` in the app module
 */
@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class FmntsIconsModule {}
