type ArrayItemCallback<T, R> = (item: T) => R;

/**
 * @param other Other array that may include values
 *
 * @returns
 * A function that takes a value and that will return
 * `true` if `other` includes that value.
 * This is so that the returned function can be used
 * in array methods like `some` or `every`.
 *
 * @example
 * // With some
 * ['a', 'b'].some(includedIn(['b'])) // true
 * ['a', 'b'].some(includedIn(['c'])) // false
 *
 * @example
 * // With every
 * ['a', 'b'].every(includedIn(['c', 'b', 'a'])) // true
 * ['a', 'b'].every(includedIn(['a'])) // false
 *
 */
export function includedIn<T, S extends T>(
  other: readonly S[],
): (item: T) => item is S;
export function includedIn<T>(
  other: readonly T[],
): ArrayItemCallback<T, boolean>;

export function includedIn<T>(
  other: readonly T[],
): ArrayItemCallback<T, boolean> {
  return (value) => other.includes(value);
}
