import { Nullish, isNonEmptyString, isNumber } from '@fmnts/core';

/** Internal prefix for our formunauts variables. */
const CSS_VAR_PREFIX = 'fmnts';

/**
 * @param variableName Name of variable without prefix.
 * @returns
 * Fully qualified CSS variable name.
 */
function _getFullVariableName(variableName: string): string {
  return `--${CSS_VAR_PREFIX}-${variableName}`;
}

/**
 *
 * @param variableName Name of CSS variable without prefix.
 * @returns
 * CSS variable to be used in `styles`.
 */
export function getCssVar(variableName: string): string {
  return `var(${_getFullVariableName(variableName)})`;
}

export type GetCssVarFn<TModifiers extends string> = (
  modifier: TModifiers,
) => string;

export function getCssVarFactory<TModifiers extends string>(
  variableName: string,
): GetCssVarFn<TModifiers> {
  return (modifier) => getCssVar([variableName, modifier].join('--'));
}

export type CssVarFactory<TKeys extends string> = (
  modifierVariables: Partial<Record<TKeys, string | number | Nullish | false>>,
) => Partial<Record<TKeys, string>>;

/**
 * Creates a factory for an element.
 * @param namespace Unique namespace.
 *
 * @returns
 * A function to create CSS variable mappings for the `element`.
 */
export function cssVarFactory<TKeys extends string>(
  namespace: string,
): CssVarFactory<TKeys> {
  return (modifierVariables) =>
    Object.entries(modifierVariables).reduce(
      (acc, [modifier, variableValue]) =>
        isNumber(variableValue) || isNonEmptyString(variableValue)
          ? {
              ...acc,
              [_getFullVariableName(`${namespace}--${modifier}`)]:
                `${variableValue}`,
            }
          : acc,
      {},
    );
}
