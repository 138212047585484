import { Nullish } from '../common.type';
import { not } from '../functional';

/**
 * Type guard to check if something is undefined
 */
export function isUndefined(u: unknown): u is undefined {
  return u === undefined;
}

/**
 * Type guard to check if something is null
 */
export function isNull(u: unknown): u is null {
  return u === null;
}

/**
 * Type guard to check if something is `null` or `undefined`.
 */
// TODO: Rename to isNullish
export function isNil(u: unknown): u is Nullish {
  return isNull(u) || isUndefined(u);
}

/** Type guard to check if something is defined. */
export const isDefined = not(isUndefined);
/** Type guard that checks that something is not `null`. */
export const isNotNull = not(isNull);
/** Type guard that checks that something is not `null` or `undefined`. */
export const isNotNullish = not(isNil);

/**
 * Type guard for numbers
 */
export function isNumber(u: unknown): u is number {
  return typeof u === 'number';
}

/**
 * Type guard for string
 */
export function isString(u: unknown): u is string {
  return typeof u === 'string';
}

/**
 * Type guard to check if something is the empty string.
 */
export function isEmptyString(u: unknown): u is '' {
  return u === '';
}

/**
 * Type guard to check if something is a non-empty string.
 */
export function isNonEmptyString(u: unknown): u is string {
  return isString(u) && !isEmptyString(u);
}
