import moment from 'moment';

import { Duration } from './chronos.type';

export type Period = Exclude<keyof Duration, 'quarters'>;

/**
 * Contains the list of periods sorted descending.
 */
const periodsOrderedDesc = Object.freeze<Period[]>([
  'years',
  'months',
  'weeks',
  'days',
  'hours',
  'minutes',
  'seconds',
  'milliseconds',
]);

/**
 * Compare function to sort a collection of periods descending, meaning
 * that the more significant comes first.
 *
 * @param a First value
 * @param b Second value
 * @returns
 *
 * @example
 * ['seconds', 'minutes'].sort(comparePeriodsDesc);
 * // == ['minutes', 'seconds']
 */
export const comparePeriodsDesc = (a: Period, b: Period): number =>
  periodsOrderedDesc.indexOf(a) - periodsOrderedDesc.indexOf(b);

/**
 * Helper that will transform a duration object in such a way
 * that it will only contain values for the passed `periods`.
 *
 * This means that higher periods are reduced to the most
 * significant of the given `periods`. For example, when
 * passing a duration of `{ days: 1 }` with the most significant
 * period being 'hours', it will be transformed to `{ hours: 24}`.
 *
 * It also normalizes other periods if they extend their maximum
 * value. For example `{ hours: 1, minutes: 65 }` for the periods
 * `['hours', 'minutes']` is transformed to `{ hours: 2, minutes: 5}`.
 *
 * @param duration Duration that should be reduced
 * @param periods
 * Relevant periods. This must be sorted descending.
 *
 * @returns
 * A new duration object that contains the values from the original
 * duration but reduced to only the given periods.
 *
 * @example
 * reduceByPeriods(
 *  { minutes: 1 },
 *  ['seconds']
 * ); // == { seconds: 60 }
 *
 * @example
 * reduceByPeriods(
 *  { hours: 2, minutes: 70, seconds: 62 }
 *  ['hours', 'minutes', 'seconds']
 * ); // == { hours: 3, minutes: 11, seconds: 2 }
 */
export function reduceByPeriods(
  duration: Duration,
  periods: readonly Period[],
): Duration {
  let _duration = moment.duration(duration);

  return periods.reduceRight<Duration>((acc, period, index) => {
    // All the rest of the duration will be cummulated when the
    // maximum significant period is reached
    const isMaximumSignificantPeriod = index === 0;

    const periodValue = isMaximumSignificantPeriod
      ? _duration.as(period)
      : _duration.get(period);

    if (!isMaximumSignificantPeriod) {
      _duration = _duration.subtract(periodValue, period);
    }

    return {
      ...acc,
      [period]: periodValue,
    };
  }, {});
}
