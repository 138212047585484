import {
  CssVarFactory,
  cssVarFactory,
  getCssVarFactory,
} from '../css-variable-utils';

export type TypographyProps =
  | 'font-family'
  | 'font-size'
  | 'font-weight'
  | 'line-height'
  | 'letter-spacing'
  | 'text-transform';

export const ThemeTextSizeVariants = Object.freeze({
  'text.2xs': getCssVarFactory<TypographyProps>('text-2xs'),
  'text.xs': getCssVarFactory<TypographyProps>('text-xs'),
  'text.sm': getCssVarFactory<TypographyProps>('text-sm'),
  'text.md': getCssVarFactory<TypographyProps>('text-md'),
  'text.lg': getCssVarFactory<TypographyProps>('text-lg'),
  'text.xl': getCssVarFactory<TypographyProps>('text-xl'),
  'text.2xl': getCssVarFactory<TypographyProps>('text-2xl'),
  'text.3xl': getCssVarFactory<TypographyProps>('text-3xl'),
  'text.4xl': getCssVarFactory<TypographyProps>('text-4xl'),
  'text.5xl': getCssVarFactory<TypographyProps>('text-5xl'),
});
export type ThemeTextSizeVariants = keyof typeof ThemeTextSizeVariants;

export const ThemeTextVariants = Object.freeze({
  // Display variants
  'display.sm': getCssVarFactory<TypographyProps>('display-sm'),
  'display.md': getCssVarFactory<TypographyProps>('display-md'),
  'display.lg': getCssVarFactory<TypographyProps>('display-lg'),
  // Headline variants
  'headline.1': getCssVarFactory<TypographyProps>('headline-1'),
  'headline.2': getCssVarFactory<TypographyProps>('headline-2'),
  'headline.3': getCssVarFactory<TypographyProps>('headline-3'),
  'headline.4': getCssVarFactory<TypographyProps>('headline-4'),
  'headline.5': getCssVarFactory<TypographyProps>('headline-5'),
  'headline.6': getCssVarFactory<TypographyProps>('headline-6'),
  // Titles
  'title.lg': getCssVarFactory<TypographyProps>('title-lg'),
  'title.md': getCssVarFactory<TypographyProps>('title-md'),
  'subtitle.md': getCssVarFactory<TypographyProps>('subtitle-md'),
  'overline.md': getCssVarFactory<TypographyProps>('overline-md'),
  'footer.md': getCssVarFactory<TypographyProps>('footer-md'),
  // Body text variants
  'body.lg': getCssVarFactory<TypographyProps>('body-lg'),
  'body.md': getCssVarFactory<TypographyProps>('body-md'),
  'body.sm': getCssVarFactory<TypographyProps>('body-sm'),
  'caption.md': getCssVarFactory<TypographyProps>('caption-md'),
  // Form elements
  'label.md': getCssVarFactory<TypographyProps>('label-md'),
  'label.sm': getCssVarFactory<TypographyProps>('label-sm'),
  'button.lg': getCssVarFactory<TypographyProps>('button--lg'),
  'button.md': getCssVarFactory<TypographyProps>('button--md'),
  'button.sm': getCssVarFactory<TypographyProps>('button--sm'),
});

export type ThemeTextVariants = keyof typeof ThemeTextVariants;

export function typographyLevelCssVarFactory(
  level: string,
): (textVariant: ThemeTextVariants) => CssVarFactory<TypographyProps> {
  const typographyCssVars = cssVarFactory<TypographyProps>(level);
  return (textVariant) => {
    const variant = ThemeTextVariants[textVariant];

    return (modifiers) =>
      typographyCssVars({
        'font-family': variant('font-family'),
        'font-size': variant('font-size'),
        'font-weight': variant('font-weight'),
        'letter-spacing': variant('letter-spacing'),
        'line-height': variant('line-height'),
        'text-transform': variant('text-transform'),
        ...modifiers,
      });
  };
}

export function typographyLevelCssFactory(
  textVariant: ThemeTextVariants,
): CssVarFactory<TypographyProps> {
  const variant = ThemeTextVariants[textVariant];

  return () => ({
    'font-family': variant('font-family'),
    'font-size': variant('font-size'),
    'font-weight': variant('font-weight'),
    'letter-spacing': variant('letter-spacing'),
    'line-height': variant('line-height'),
    'text-transform': variant('text-transform'),
  });
}
