/**
 * Creates unique IDs to be used in HTML.
 *
 * @param prefix Prefix for the ID
 */
export function* htmlIdMaker(
  prefix: string,
): Generator<string, never, unknown> {
  let nextUniqueId = 0;
  while (true) {
    yield `${prefix}-${++nextUniqueId}`;
  }
}
