/**
 * @param array Array to check
 *
 * @returns
 * `true`, if the passed `array` has a length of `1`
 *
 * @example
 * isTuple([], 1) // false
 * isTuple(['a'], 1) // true
 */
export function isTuple<T>(array: T[], length: 1): array is [T];

/**
 * @param array Array to check
 *
 * @returns
 * `true`, if the passed `array` has a length of `2`
 *
 * @example
 * isTuple([], 2) // false
 * isTuple(['a'], 2) // false
 * isTuple(['a', 'b'], 2) // true
 */
export function isTuple<T>(array: T[], length: 2): array is [T, T];

/**
 * @param array Array to check
 *
 * @returns
 * `true`, if the passed `array` has a length of `3`
 *
 * @example
 * isTuple([], 3) // false
 * isTuple(['a'], 3) // false
 * isTuple(['a', 'b'], 3) // false
 * isTuple(['a', 'b', 'c'], 3) // true
 */
export function isTuple<T>(array: T[], length: 3): array is [T, T, T];

/**
 *
 * @param array Array to check
 * @param length Length to have
 *
 * @returns
 * `true`, if the passed `array` has a length of `length`.
 */
export function isTuple<T>(array: T[], length: number): boolean {
  return array.length === length;
}
