import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';

/**
 * A DI token with a reference to the window containing a DOM document.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window
 */
export const WINDOW = new InjectionToken<Window>(
  '@fmnts.common.browswer-api.window',
  {
    factory: () => {
      const { defaultView } = inject(DOCUMENT);

      if (!defaultView) {
        throw new Error('Window is not available');
      }

      return defaultView;
    },
  },
);

/**
 * A DI token with a reference to the Navigator object, which has methods
 * and properties about the application running the script.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
 */
export const NAVIGATOR = new InjectionToken<Navigator>(
  '@fmnts.common.browswer-api.navigator',
  {
    factory: () => inject(WINDOW).navigator,
  },
);
