import {
  animate,
  AnimationMetadata,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AnimationTimingInfo,
  timingByThemeTokens,
} from '@fmnts/components/core';

const _smallAnimationTiming: AnimationTimingInfo = {
  duration: 'duration.short-2',
  curve: 'ease.standard',
};

/**
 * Common animations used for icons.
 */
export const fmntsIconAnimations: {
  /**
   * Rotates icon by given degree.
   *
   * * @example
   * // (e.g. dropdown icon).
   * ```html
   * <fa-icon
   *  [icon]="iconArrowUp"
   *  [@fmntsIconRotateZ]="open ? 180 : 0"
   * ></fa-icon>
   * ```
   */
  readonly rotateZ: AnimationTriggerMetadata;
} = {
  rotateZ: trigger(
    'fmntsIconRotateZ',
    _transform(
      {
        0: 'rotate(0deg)',
        1: 'rotate(1deg)',
        2: 'rotate(2deg)',
        3: 'rotate(3deg)',
        6: 'rotate(6deg)',
        12: 'rotate(12deg)',
        45: 'rotate(45deg)',
        90: 'rotate(90deg)',
        180: 'rotate(180deg)',
        270: 'rotate(270deg)',
      },
      _smallAnimationTiming,
    ),
  ),
};

function _transform(
  x: Record<string, string>,
  timing: AnimationTimingInfo,
): AnimationMetadata[] {
  const result: AnimationMetadata[] = [];
  const names: string[] = [];

  for (const [stateName, transform] of Object.entries(x)) {
    names.push(stateName);
    result.push(state(stateName, style({ transform })));
  }

  result.push(transition('* <=> *', animate(timingByThemeTokens(timing))));

  return result;
}
