export enum TraversalResult {
  /**
   * Traversal is done
   */
  Done,
  /**
   * Continue traversal on children
   */
  Continue,
  /**
   * Subtree can be skipped.
   */
  Skip,
}

type TreeNodePath<TNodeValue> = TreeNode<TNodeValue>[];

/**
 * Helper that transforms a predicate function that returns a `boolean`
 * into a predicate function that returns a `TraversalResult`.
 */
function traversalResultPredicate<T>(predicate: (value: T) => boolean) {
  return (value: T) =>
    predicate(value) ? TraversalResult.Done : TraversalResult.Continue;
}

/**
 * Represents a node used in `Tree`.
 */
export class TreeNode<TNodeValue> {
  constructor(
    protected _value: TNodeValue,
    protected _children: TreeNode<TNodeValue>[],
  ) {}

  /**
   * @param path The path of nodes
   *
   * @returns
   * The latest in the path chain or undefined
   */
  protected static popPath<T extends TreeNode<unknown>>(
    path: T[],
  ): T | undefined {
    return [...path].pop();
  }

  /**
   * The value for the node
   */
  public get value(): TNodeValue {
    return this._value;
  }

  /**
   * Child nodes
   */
  public get children(): TreeNode<TNodeValue>[] {
    return this._children;
  }

  /**
   * @param predicate
   * Function that is called with the node.
   * Traversal can be controlled with the return value.
   *
   * @returns
   * Path to the node as an array of nodes.
   */
  public findPath(
    predicate: (node: TreeNode<TNodeValue>) => TraversalResult,
  ): TreeNodePath<TNodeValue> {
    const result = predicate(this);
    switch (result) {
      case TraversalResult.Done:
        return [this];
      case TraversalResult.Continue:
        for (const child of this.children) {
          const path = child.findPath(predicate);
          if (path.length) {
            path.unshift(this);
            return path;
          }
        }
    }

    return [];
  }

  /**
   * @param predicate
   * Function that is called with each node.
   * Traversal can be controlled with the return value.
   *
   * @returns
   * Array of node paths. Each item in this array is a
   * path that returned `TraversalResult.Done`.
   */
  public findAllPaths(
    predicate: (node: TreeNode<TNodeValue>) => TraversalResult,
  ): TreeNodePath<TNodeValue>[] {
    const result = predicate(this);
    switch (result) {
      case TraversalResult.Done:
        return [[this]];
      case TraversalResult.Continue:
        const paths: TreeNode<TNodeValue>[][] = [];
        for (const child of this.children) {
          paths.push(...child.findAllPaths(predicate));
        }
        if (paths.length) {
          for (const path of paths) {
            path.unshift(this);
          }
          return paths;
        }
    }

    return [];
  }

  /**
   * @param predicate
   * Function that is called with each node.
   * Traversal can be controlled with the return value.
   *
   * @returns
   * Node for which the `predicate` returned `TraversalResult.Done`.
   */
  public findNode(
    predicate: (node: TreeNode<TNodeValue>) => TraversalResult,
  ): TreeNode<TNodeValue> | undefined {
    const path = this.findPath(predicate);
    return TreeNode.popPath(path);
  }

  /**
   * @param predicate
   * Function that is called with each node.
   * Traversal can be controlled with the return value.
   *
   * @returns
   * All nodes for which the `predicate` returned `TraversalResult.Done`.
   */
  public findAllNodes(
    predicate: (node: TreeNode<TNodeValue>) => TraversalResult,
  ): TreeNode<TNodeValue>[] {
    const nodes: TreeNode<TNodeValue>[] = [];

    for (const path of this.findAllPaths(predicate)) {
      const node = TreeNode.popPath(path);
      if (node) {
        nodes.push(node);
      }
    }

    return nodes;
  }

  /**
   * @param predicate
   * Function that is called with the node.
   * If `true` is returned, the node will be returned.
   *
   * @returns
   * Node, for which the `predicate` function returned `true`.
   */
  public queryNode(
    predicate: (node: TreeNode<TNodeValue>) => boolean,
  ): TreeNode<TNodeValue> | undefined {
    return this.findNode(traversalResultPredicate(predicate));
  }

  /**
   * @param predicate
   * Function that is called with each node.
   * If `true` is returned for a node, the path to this node
   * is added to the result paths.
   *
   * @returns
   * The paths to all nodes, for which the `predicate` returned
   * `true`.
   */
  public queryAllPaths(
    predicate: (node: TreeNode<TNodeValue>) => boolean,
  ): TreeNodePath<TNodeValue>[] {
    return this.findAllPaths(traversalResultPredicate(predicate));
  }

  /**
   * @param predicate
   * Function that is called with each node.
   * If `true` is returned, the node will be returned.
   *
   * @returns
   * All nodes for which the `predicate` returned `true`.
   */
  public queryAllNodes(
    predicate: (node: TreeNode<TNodeValue>) => boolean,
  ): TreeNode<TNodeValue>[] {
    return this.findAllNodes(traversalResultPredicate(predicate));
  }

  /**
   * @param value The value to find
   *
   * @returns
   * The node with the given `value`
   */
  public find(value: TNodeValue): TreeNode<TNodeValue> | undefined {
    return this.queryNode((node) => node.value === value);
  }
}

/**
 * Represents a tree-like data structure.
 */
export class Tree<TNodeValue> {
  constructor(protected _root: TreeNode<TNodeValue>) {}

  public get root(): TreeNode<TNodeValue> {
    return this._root;
  }
}
