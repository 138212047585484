/**
 * Represents a value of one of two possible types (a disjoint union).
 *
 * An instance of Either is either an instance of Left or Right.
 *
 * A common use of Either is as an alternative to Option for dealing with possible missing values.
 * In this usage, None is replaced with a Left which can contain useful information.
 * Right takes the place of Some.
 * Convention dictates that Left is used for failure and Right is used for success.
 *
 * @internal
 * Don't export this directly yet , but provide functionality via `option`
 */
export type Either<Left, Right> = _Either<Left, Right> &
  TypedEither<Left, Right>;

/**
 * This type is used only internally here and is an unsafe variant for
 * `Either`.
 *
 * @internal
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
type _Either<Left, Right> = Left | Right;

/**
 * Type safe variant that guards against types where `Right` might extend from
 * `Left`.
 */
export type TypedEither<Left, Right> = Right extends Left
  ? `Right MUST NOT extend Left` & never
  : unknown;

type EitherGuardFn<Left, Right> = (
  value: Either<Left, any> & TypedEither<Left, Right>,
) => value is Left;

/**
 * Creates a function that when called with `Either` left or right will call
 * the according `onLeft` or `onRight` function and return the respective
 * value.
 *
 * @param isLeft Type guard to distinguish left from right.
 */
export function matchEither<
  Left,
  Right,
  LeftResult = Left,
  RightResult = Right,
>(
  isLeft: EitherGuardFn<Left, Right>,
  {
    onLeft,
    onRight,
  }: {
    /** Transforms left value. */
    onLeft: (value: Left) => LeftResult;
    /** Transforms right value. */
    onRight: (value: Right) => RightResult;
  },
): (value: Either<Left, Right>) => _Either<LeftResult, RightResult> {
  return (value) => (isLeft(value) ? onLeft(value) : onRight(value));
}
