import { Injectable } from '@angular/core';

/**
 * Service that holds the configuration for the API.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiConfigService {
  /**
   * Base URL for API
   */
  public get apiUrl(): string {
    return this._apiUrl;
  }
  public set apiUrl(value: string) {
    this._apiUrl = value;
  }
  private _apiUrl = '';

  /**
   * URL for cockpit API
   */
  public get prefixCockpit(): string {
    return this._prefixCockpit;
  }
  public set prefixCockpit(prefixCockpit: string) {
    this._prefixCockpit = prefixCockpit;
  }
  private _prefixCockpit = '/api-cockpit/';

  public getEndpointUrl(suffix: string = ''): string {
    return this.sanitizeUrl(`${this.apiUrl}/${suffix}/`);
  }

  public getCockpitEndpointUrl(suffix: string = ''): string {
    return this.buildApiUrl([this._prefixCockpit, suffix]);
  }

  /**
   * @param parts Parts of the url that should be joined
   *
   * @returns
   * Complete URL for the given parts
   */
  public buildApiUrl(parts: (string | number)[]): string {
    const url = [...parts, ''].join('/');

    return this.getEndpointUrl(url);
  }

  /**
   * @param parts Parts of the url that should be joined
   *
   * @returns
   * Complete URL for the given parts
   */
  public buildCockpitApiUrl(parts: (string | number)[]): string {
    const url = [...parts, ''].join('/');

    return this.getCockpitEndpointUrl(url);
  }

  /**
   * Test if the URL is either relative (matching a single /) or the base URL.
   * @param url
   * @returns
   */
  public isOurEndpoint(url: string): boolean {
    return (
      /^\/[^\/]/.test(url) ||
      url.startsWith(this.getEndpointUrl()) ||
      url.startsWith(window.location.origin)
    );
  }

  private sanitizeUrl(url: string): string {
    return url
      .replace(/[//]+/g, '/')
      .replace(/(http:|https:)\/(?!\/)/g, '$1//');
  }
}
