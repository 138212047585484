import auth from './auth.it.json';
import cockpit from './cockpit.it.json';
import common from './common.it.json';
import next from './next.it.json';
import verify from './verify.it.json';

export default {
  ...auth,
  ...cockpit,
  ...common,
  ...next,
  ...verify,
};
