import { Vector2d } from './vector2d';

/**
 * Immutable representation of a Bezier curve.
 */
export class BezierCurve {
  public static fromPoints(
    p: [Vector2d, Vector2d, Vector2d, Vector2d] | Vector2d[],
  ): BezierCurve {
    const c2 = BezierCurve.calculateControlPoints(p[0], p[1], p[2]).c2;
    const c3 = BezierCurve.calculateControlPoints(p[1], p[2], p[3]).c1;

    return new BezierCurve(p[1], c2, c3, p[2]);
  }

  /**
   * Calculate the two control points between three points (start, mid, end),
   * which leads to a curve that goes through all three points
   *
   * @param p1
   * @param p2
   * @param p3
   * @returns
   */
  protected static calculateControlPoints(
    p1: Vector2d,
    p2: Vector2d,
    p3: Vector2d,
  ): { c1: Vector2d; c2: Vector2d } {
    const l1 = Vector2d.distance(p1, p2);
    const l2 = Vector2d.distance(p2, p3);
    const k = l2 / (l1 + l2);

    const m1 = p1.add(p2).scale(0.5);
    const m2 = p2.add(p3).scale(0.5);
    const cm = m2.add(m1.subtract(m2).scale(k));
    const t = p2.subtract(cm);

    return {
      c1: m1.add(t),
      c2: m2.add(t),
    };
  }

  /**
   * Calculate the x or y value of a point on the Bezier path based on a
   * percent-based value t, indicating how far along the point is on the path.
   *
   * @param t Indicator
   * @param c1 Start point
   * @param c2 1st control point
   * @param c3 2nd control point
   * @param c4 end point
   *
   * @returns
   * Coordinate along a Bezier curve with `t` indicating the point along
   * an interval [0;1].
   *
   * @see https://en.wikipedia.org/wiki/B%C3%A9zier_curve#Cubic_B.C3.A9zier_curves
   */
  protected static coordinate(
    t: number,
    c1: number,
    c2: number,
    c3: number,
    c4: number,
  ): number {
    const f = 1.0 - t;

    // prettier-ignore
    return       c1 * f * f * f
         + 3.0 * c2 * f * f * t
         + 3.0 * c3 * f * t * t
         +       c4 * t * t * t;
  }

  constructor(
    /** Start point */
    public readonly c1: Vector2d,
    /** 1. control point */
    public readonly c2: Vector2d,
    /** 2. control point */
    public readonly c3: Vector2d,
    /** End point */
    public readonly c4: Vector2d,
  ) {}

  /**
   * Approximate the length of the bezier path, by calculating 10 linear
   * segments of the curve.
   * @returns
   */
  length(): number {
    const steps = 10;
    let length = 0;
    let p = Vector2d.zero;

    for (let i = 0; i < steps; i++) {
      const t = i / steps;
      const c = this.point(t);

      if (i > 0) {
        length += c.subtract(p).magnitude;
      }

      p = c;
    }

    return length;
  }

  /**
   * @param t A value between 0 and 1
   * @returns
   * A point along the Bezier curve.
   */
  point(t: number): Vector2d {
    return new Vector2d(
      BezierCurve.coordinate(t, this.c1.x, this.c2.x, this.c3.x, this.c4.x),
      BezierCurve.coordinate(t, this.c1.y, this.c2.y, this.c3.y, this.c4.y),
    );
  }
}
