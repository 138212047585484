import { distinctByLiteralProperty, hasProperty, isString } from '@fmnts/core';
import { ApiErrorBase } from './api-model';
import { ApiErrorType } from './domain-enums';

function hasApiErrorType(value: unknown): value is ApiErrorType {
  return (
    isString(value) &&
    (
      [
        ApiErrorType.Client,
        ApiErrorType.Conflict,
        ApiErrorType.Permission,
        ApiErrorType.Server,
        ApiErrorType.Validation,
      ] as string[]
    ).includes(value)
  );
}

const hasKnownFmntsApiErrorType = hasProperty('error_type', hasApiErrorType);

/**
 * Checks if the passed value is an `ErrorResponse`.
 * Use this with error information from a HTTP request against the formunauts API.
 *
 * @example
 * private handleError(httpError: HttpErrorResponse) {
 *  if (httpError.status === 0) {
 *    // A client-side or network error occurred. Handle it accordingly.
 *    return throwError(...);
 *  }
 *  // The backend returned an unsuccessful response code.
 *  // The response body may contain clues as to what went wrong.
 *  if (isFmntsApiErrorResponse(httpError.error)) {
 *    return throwError(() => new FmntsApiError({
 *      apiError: httpError.error.error,
 *      httpError,
 *    });)
 *  }
 *  // Return an observable with a user-facing error message.
 *  return throwError(() => new Error('Something bad happened; please try again later.'));
 * }
 *
 * getConfig() {
 *  return this.http.get(this.configUrl).pipe(
 *    catchError(this.handleError)
 *  );
 * }
 */
export const isFmntsApiErrorResponse = hasProperty(
  'error',
  (u: unknown): u is ApiErrorBase => hasKnownFmntsApiErrorType(u),
);

/**
 * Implementation for errors from API.
 *
 * @example
 * throw new FmntsApiError({ ... });
 */
export class FmntsApiError extends Error {
  override readonly name = 'FmntsApiError';

  /**
   * Fixed error type to indicate if and how this error can be shown to the user or not.
   */
  readonly errorType: ApiErrorType;
  /** Technical error message. */
  readonly originalMessage: string;
  /**
   * Error details suitable to display to the user. The exact type
   * depends on the `errorType`.
   */
  readonly details: unknown;
  /** Status code */
  readonly statusCode: number;

  constructor({
    apiError,
    statusCode,
  }: {
    apiError: ApiErrorBase;
    statusCode: number;
  }) {
    super(`[${apiError.error_type}/${statusCode}] - ${apiError.message}`);

    this.originalMessage = apiError.message;
    this.errorType = apiError.error_type;
    this.details = apiError.details;
    this.statusCode = statusCode;
  }
}

/**
 * Client error.
 */
export interface FmntsApiClientError extends FmntsApiError {
  errorType: ApiErrorType.Client;
}

/**
 * Some conflict on the resource occured.
 */
export interface FmntsApiConflictError extends FmntsApiError {
  errorType: ApiErrorType.Conflict;
}

/**
 * Permission error on the resource.
 */
export interface FmntsApiPermissionError extends FmntsApiError {
  errorType: ApiErrorType.Permission;
}

/**
 * Error on the server occured.
 */
export interface FmntsApiServerError extends FmntsApiError {
  errorType: ApiErrorType.Server;
}

/**
 * POST/PUT/PATCH on resource wasn't successful due to invalid data.
 */
export interface FmntsApiValidationError extends FmntsApiError {
  errorType: ApiErrorType.Validation;
  /**
   * Keys are fieldnames and values are validation errors for
   * this field.
   */
  details: Record<string, string[]>;
}

/**
 * Type guards for Formunauts API error types.
 * This is wrapped in an anomynous function because we won't need the
 * helper function after the type guard functions are created.
 */
export const {
  [ApiErrorType.Client]: isFmntsApiClientError,
  [ApiErrorType.Conflict]: isFmntsApiConflictError,
  [ApiErrorType.Permission]: isFmntsApiPermissionError,
  [ApiErrorType.Server]: isFmntsApiServerError,
  [ApiErrorType.Validation]: isFmntsApiValidationError,
} = (function () {
  const distinctErrorType =
    distinctByLiteralProperty<FmntsApiError>()('errorType');

  return {
    /** Type guard for API client error. */
    [ApiErrorType.Client]: distinctErrorType<FmntsApiClientError>(
      ApiErrorType.Client,
    ),
    /** Type guard for API client error. */
    [ApiErrorType.Conflict]: distinctErrorType<FmntsApiConflictError>(
      ApiErrorType.Conflict,
    ),
    /** Type guard for API permission error. */
    [ApiErrorType.Permission]: distinctErrorType<FmntsApiPermissionError>(
      ApiErrorType.Permission,
    ),
    /** Type guard for API server error. */
    [ApiErrorType.Server]: distinctErrorType<FmntsApiServerError>(
      ApiErrorType.Server,
    ),
    /** Type guard for API validation error. */
    [ApiErrorType.Validation]: distinctErrorType<FmntsApiValidationError>(
      ApiErrorType.Validation,
    ),
  };
})();
