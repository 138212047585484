import { isNumber } from '@fmnts/core';
import moment from 'moment';

import {
  Duration,
  DurationCompareOptions,
  TimeSpanInput,
} from './chronos.type';
import { isAfter, isBefore } from './compare-date';

/**
 * @param duration Duration to check
 * @param opts Options for the check
 *
 * @returns
 * A function that can be called with timespan.
 * If the timespan is at least as long apart as specified
 * by the `duration`, the function returns `true`, otherwise `false`.
 *
 *
 * @example
 * const moreOrFiveYears = greaterOrEqualToDuration({years: 5});
 * moreOrFiveYears(['2010-01-01', '2015-01-01']); // true
 * moreOrFiveYears(['2010-01-01', '2014-01-01']); // false
 * moreOrFiveYears(['2010-01-01', '2014-12-31']); // false
 */
export function greaterOrEqualToDuration(
  duration: Duration,
  opts: DurationCompareOptions = {},
): (value: TimeSpanInput) => boolean {
  const { granularity } = opts;
  const mDuration = moment.duration(duration);

  return (value) => {
    const [from, to] = value;
    const maxDate = moment(to).clone().subtract(mDuration);

    return isBefore(maxDate, {
      exclusive: false,
      granularity,
    })(from);
  };
}

/**
 * @param duration Duration to check
 * @param opts Options for the check
 *
 * @returns
 * A function that can be called with timespan.
 * If the timespan is at least as long apart as specified
 * by the `duration`, the function returns `true`, otherwise `false`.
 *
 *
 * @example
 * const moreThanFiveYears = greaterThanDuration({years: 5});
 * moreThanFiveYears(['2010-01-01', '2016-01-01']); // true
 * moreThanFiveYears(['2010-01-01', '2015-01-01']); // false
 * moreThanFiveYears(['2010-01-01', '2015-12-31']); // false
 */
export function greaterThanDuration(
  duration: Duration,
  opts: DurationCompareOptions = {},
): (value: TimeSpanInput) => boolean {
  const { granularity } = opts;
  // Increase given duration value by one
  const adaptedDuration = Object.entries(duration).reduce(
    (acc, [key, value]) =>
      isNumber(value) ? { ...acc, [key]: value + 1 } : acc,
    {} as Duration,
  );

  // Now create the check function from the new duration and with exclusivity
  return greaterOrEqualToDuration(adaptedDuration, { granularity });
}

/**
 * @param duration Duration to check
 * @param opts Options for the check
 *
 * @returns
 * A function that can be called with timespan.
 * If the timespan is not longer apart as specified
 * by the `duration`, the function returns `true`, otherwise `false`.
 *
 *
 * @example
 * const lessThanFiveYears = lessThanDuration({years: 5});
 * lessThanFiveYears(['2010-01-01', '2015-01-01']); // false
 * lessThanFiveYears(['2010-01-02', '2015-01-01']); // true
 * lessThanFiveYears(['2010-01-01', '2014-12-31']); // true
 */
export function lessThanDuration(
  duration: Duration,
  opts: DurationCompareOptions = {},
): ([from, to]: TimeSpanInput) => boolean {
  const { granularity } = opts;
  const mDuration = moment.duration(duration);

  return ([from, to]) => {
    const minDate = moment(to).clone().subtract(mDuration);

    return isAfter(minDate, {
      exclusive: true,
      granularity,
    })(from);
  };
}

/**
 * @param duration Duration to check
 * @param granularity Granularity for the check
 *
 * @returns
 * A function that can be called with timespan.
 * If the timespan is not longer apart as specified
 * by the `duration`, the function returns `true`, otherwise `false`.
 *
 *
 * @example
 * const checkFn = lessOrEqualToDuration({ years: 5 })
 * checkFn(['2010-06-02', '2015-06-02']) // true
 * checkFn(['2009-06-03', '2015-06-02']) // true
 * checkFn(['2009-06-02', '2015-06-02']) // false
 */
export function lessOrEqualToDuration(
  duration: Duration,
  opts: DurationCompareOptions = {},
): ([from, to]: TimeSpanInput) => boolean {
  const { granularity } = opts;
  // Increase given duration value by one
  const adaptedDuration = Object.entries(duration).reduce(
    (acc, [key, value]) =>
      isNumber(value) ? { ...acc, [key]: value + 1 } : acc,
    {} as Duration,
  );

  // Now create the check function from the new duration and with exclusivity
  return lessThanDuration(adaptedDuration, { granularity });
}
