import { getCssVar } from '../css-variable-utils';

/**
 * Pre-defined animation curves.
 * Use this with `@angular/animations` or other libraries to mimic CSS animations.
 *
 * Which easing set to use?
 * - `emphasized` is prefered for most animations
 * - `standard` can be used for small utility focused transitions.
 *
 * @internal
 */
export const AnimationCurves = Object.freeze({
  /**
   * Standard easing subtly brings attention to the end of an animation
   * by taking more time to decelerate than accelerate. Standard easing
   * is the most common form of easing.
   *
   * Elements that begin and end at rest use standard easing. They
   * speed up quickly and slow down gradually, in order to emphasize
   * the end of the transition.
   */
  'ease.standard': 'cubic-bezier(0.4,0.0,0.2,1)',
  /**
   * Incoming elements are animated using decelerated easing, in which
   * transitions begin at peak velocity (the fastest point in an
   * element's movement) and end at rest.
   */
  'ease.standard-decelerate': 'cubic-bezier(0.0,0.0,0.2,1)',
  /**
   * Elements exiting a screen use accelerated easing, a transition
   * in which the element starts at rest and ends at peak velocity.
   */
  'ease.standard-accelerate': 'cubic-bezier(0.4,0.0,1,1)',
  /**
   * In comparison to standard easing, emphasized easing draws extra
   * attention at the end of an animation. Emphasized easing is
   * usually paired with longer durations to convey a more stylized
   * sense of speed.
   *
   * Elements that begin and end at rest use emphasized easing.
   * They speed up quickly and slow down very gradually, placing extra
   * emphasis on the end of the transition.
   */
  'ease.emphasized': 'cubic-bezier(0.4,0.0,0.6,1)',
  /**
   * Use for elements entering the screen.
   */
  'ease.emphasized-decelerate': 'cubic-bezier(0.15, 0.7, 0.3, 1.0)',
  /**
   * Use for elements exiting the screen.
   */
  'ease.emphasized-accelerate': 'cubic-bezier(0.3, 0.0, 0.8, 0.15)',
});

export type ThemeEasingToken = keyof typeof AnimationCurves;
export const ThemeEasing = Object.freeze<Record<ThemeEasingToken, string>>({
  // Standard set
  'ease.standard': getCssVar('ease--standard'),
  'ease.standard-accelerate': getCssVar('ease--standard-accelerate'),
  'ease.standard-decelerate': getCssVar('ease--standard-decelerate'),
  // emphasized set
  'ease.emphasized': getCssVar('ease--emphasized'),
  'ease.emphasized-accelerate': getCssVar('ease--emphasized-accelerate'),
  'ease.emphasized-decelerate': getCssVar('ease--emphasized-decelerate'),
});

/**
 * Pre-defined animation durations.
 * Each duration is expressed as a number in milliseconds.
 *
 * Use this with `@angular/animations` or other libraries to mimic CSS animations
 * or with timer / interval utils.
 *
 * @internal
 */
export const AnimationDurations = Object.freeze({
  // Short variants
  'duration.short-1': 50,
  'duration.short-2': 100,
  'duration.short-3': 150,
  'duration.short-4': 200,
  // Medium variants
  'duration.medium-1': 250,
  'duration.medium-2': 300,
  'duration.medium-3': 350,
  'duration.medium-4': 400,
  // Long variants
  'duration.long-1': 450,
  'duration.long-2': 500,
  'duration.long-3': 550,
  'duration.long-4': 600,
  // Extra long variants
  'duration.extra-long-1': 700,
  'duration.extra-long-2': 800,
  'duration.extra-long-3': 900,
  'duration.extra-long-4': 1000,
});

export type ThemeDurationToken = keyof typeof AnimationDurations;
export const ThemeDurations = Object.freeze<Record<ThemeDurationToken, string>>(
  {
    // Short variants
    'duration.short-1': getCssVar('duration--short-1'),
    'duration.short-2': getCssVar('duration--short-2'),
    'duration.short-3': getCssVar('duration--short-3'),
    'duration.short-4': getCssVar('duration--short-4'),
    // Medium variants
    'duration.medium-1': getCssVar('duration--medium-1'),
    'duration.medium-2': getCssVar('duration--medium-2'),
    'duration.medium-3': getCssVar('duration--medium-3'),
    'duration.medium-4': getCssVar('duration--medium-4'),
    // Long variants
    'duration.long-1': getCssVar('duration--long-1'),
    'duration.long-2': getCssVar('duration--long-2'),
    'duration.long-3': getCssVar('duration--long-3'),
    'duration.long-4': getCssVar('duration--long-4'),
    // Extra long variants
    'duration.extra-long-1': getCssVar('duration--extra-long-1'),
    'duration.extra-long-2': getCssVar('duration--extra-long-2'),
    'duration.extra-long-3': getCssVar('duration--extra-long-3'),
    'duration.extra-long-4': getCssVar('duration--extra-long-4'),
  },
);
