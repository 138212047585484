import auth from './auth.ro.json';
import cockpit from './cockpit.ro.json';
import common from './common.ro.json';
import next from './next.ro.json';
import verify from './verify.ro.json';

export default {
  ...auth,
  ...cockpit,
  ...common,
  ...next,
  ...verify,
};
