import auth from './auth.es-ar.json';
import cockpit from './cockpit.es-ar.json';
import common from './common.es-ar.json';
import next from './next.es-ar.json';
import verify from './verify.es-ar.json';

export default {
  ...auth,
  ...cockpit,
  ...common,
  ...next,
  ...verify,
};
