/**
 * Ring buffer is a collection data type that provides FIFO (first in, first out)
 * access. It has a fixed size and in case of an overflow it will overwrite the
 * oldest data first.
 *
 * @example
 * const buf = new RingBuffer<string>(3);
 * buf.push('first');
 * buf.push('second');
 * buf.push('third');
 * buf.push('fourth');
 * buf.pop() // -> 'second'
 * buf.pop() // -> 'third'
 * buf.pop() // -> 'fourth'
 */
export class RingBuffer<T> implements Iterable<T> {
  private _data: T[] = new Array<T>(this.size);
  /** Index for write operation. */
  private _nextWrite = 0;
  /** Index for read operation. */
  private _nextRead = 0;

  /**
   * Number of items that are currently stored in the buffer.
   * It is between `0` and `size`.
   */
  get length(): number {
    return this._length;
  }
  private _length = 0;

  /**
   * Whether there is no data to be read.
   */
  get isEmpty(): boolean {
    return this.length === 0;
  }

  constructor(
    /**
     * The size of the buffer.
     */
    private readonly size: number,
  ) {}

  *[Symbol.iterator](): IterableIterator<T> {
    while (!this.isEmpty) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      yield this.pop()!;
    }
  }

  /**
   * Adds the given `item` to the buffer.
   *
   * @param item Item to add
   */
  push(item: T): void {
    this._data[this._nextWrite] = item;
    this._updateOnItemsAdded(1);
  }

  /**
   * Reads the first `item` from the buffer.
   *
   * @returns
   * The first item or `undefined` if empty.
   */
  pop(): T | undefined {
    if (this.isEmpty) {
      return undefined;
    }

    const item = this._data[this._nextRead];
    delete this._data[this._nextRead];
    this._updateOnItemsRemoved(1);

    return item;
  }

  private _updateOnItemsAdded(delta: number) {
    this._nextWrite = this._advanceIndex(this._nextWrite + delta);
    this._length = Math.min(this._length + 1, this.size);
    // In case that the buffer was wrapped around, we also need to advance the next read position
    this._nextRead = (this.size + this._nextWrite - this._length) % this.size;
  }

  private _updateOnItemsRemoved(delta: number) {
    this._nextRead = this._advanceIndex(this._nextRead + 1);
    this._length = Math.max(this._length - delta, 0);
  }

  private _advanceIndex(i: number): number {
    return i % this.size;
  }
}
