/**
 * @param array Array of items
 *
 * @returns
 * The unique items of the given `array`.
 * Objects are not checked for equality in terms of
 * value but rather reference.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set}
 */
export function getUnique<T>(array: T[]): T[] {
  return [...new Set(array)];
}
