import auth from './auth.nl.json';
import cockpit from './cockpit.nl.json';
import common from './common.nl.json';
import next from './next.nl.json';
import verify from './verify.nl.json';

export default {
  ...auth,
  ...cockpit,
  ...common,
  ...next,
  ...verify,
};
