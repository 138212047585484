import de from './locales/de';
import en from './locales/en';
import esAr from './locales/es-ar';
import fr from './locales/fr';
import hu from './locales/hu';
import it from './locales/it';
import lb from './locales/lb';
import lt from './locales/lt';
import nl from './locales/nl';
import ro from './locales/ro';

export default {
  'es-ar': esAr,
  de,
  en,
  fr,
  hu,
  it,
  lb,
  lt,
  nl,
  ro,
};
