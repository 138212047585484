import {
  AdaptOrNull,
  ApiArrayAdapter,
  ApiDateAdapter,
  ApiDatetimeAdapter,
  ApiNamedEntityRefAdapter,
  FormDataAdapter,
  ToApiDtoAdapter,
} from './common-api.adapter';

export const apiDateAdapter = new ApiDateAdapter();
export const apiDatetimeAdapter = new ApiDatetimeAdapter();
export const apiNamedEntityRefAdapter = new ApiNamedEntityRefAdapter();

// Shared adapters that convert objects for the API
export const toApiDtoAdapter = new ToApiDtoAdapter();
export const formDataAdapter = new FormDataAdapter();

// Higher order adapters
export const apiAdaptOrNull = new AdaptOrNull();
export const apiArrayAdapter = new ApiArrayAdapter();
