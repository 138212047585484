import {
  AnimationCurves,
  AnimationDurations,
  ThemeDurationToken,
  ThemeEasingToken,
} from '../design-tokens/theme-animations';

export interface AnimationTimingInfo {
  duration: ThemeDurationToken;
  delay?: ThemeDurationToken;
  curve?: ThemeEasingToken;
}

/**
 * Timing to be used in `@angular/animation`s functions such as
 * `animate`.
 *
 * @returns
 * Timing as string.
 *
 * @see {@link https://angular.io/api/animations/animate#syntax-examples}
 */
export function timingByThemeTokens({
  duration,
  delay,
  curve,
}: AnimationTimingInfo): string {
  const timingParts: string[] = [`${AnimationDurations[duration]}ms`];
  if (delay) {
    timingParts.push(`${AnimationDurations[delay]}ms`);
  }
  if (curve) {
    timingParts.push(AnimationCurves[curve]);
  }

  return timingParts.join(' ');
}
