import { PlatformModule } from '@angular/cdk/platform';
import { NgModule } from '@angular/core';

import { CustomStyleService } from './custom-style.service';
import { TitleService } from './title.service';

@NgModule({
  imports: [PlatformModule],
  providers: [CustomStyleService, TitleService],
})
export class FmntsBrowserModule {}
