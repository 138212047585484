import { getCssVar } from '../css-variable-utils';

/**
 * Known CSS variables for spacing.
 */
export const ThemeSpacing = Object.freeze({
  'space.0': getCssVar('space-0'),
  'space.025': getCssVar('space-025'),
  'space.050': getCssVar('space-050'),
  'space.075': getCssVar('space-075'),
  'space.100': getCssVar('space-100'),
  'space.150': getCssVar('space-150'),
  'space.200': getCssVar('space-200'),
  'space.250': getCssVar('space-250'),
  'space.300': getCssVar('space-300'),
  'space.400': getCssVar('space-400'),
  'space.500': getCssVar('space-500'),
  'space.600': getCssVar('space-600'),
  'space.800': getCssVar('space-800'),
  'space.1000': getCssVar('space-1000'),
});

export type ThemeSpacing = keyof typeof ThemeSpacing;
