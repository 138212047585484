import { distinctByLiteralProperty } from '@fmnts/core';
import { Customer } from './customer';
import { LocationType } from './domain-enums';
import { EntityId, EntityKind, EntityRef } from './entity';
import { DateTimeIsoString, LegacyGeoCoordinates } from './shared-api.types';

export type LocationCommentId = LocationComment['id'];
export type LocationPermitId = LocationPermit['id'];
export type LocationId = EntityId;

export interface LocationComment {
  /** Unique comment ID */
  id: EntityId;
  /** ID of the user that created the comment */
  userId: EntityId;
  /** Name of the user that created the comment */
  fullName: string;
  /** Comment text */
  text: string;
  /** Timestamp when the comment was created */
  created: DateTimeIsoString;
}

export interface LocationPermit {
  /** Unique ID */
  id: EntityId;
  /** URL to the permit file that was uploaded */
  file: string;
  /** Timestamp when the item was created */
  created: DateTimeIsoString;
}

export interface LocationMinimal {
  id: EntityId;
  code: string;
  name: string;
  city: string;
}

export interface LocationBase extends LocationMinimal {
  capacity: number | null;
  isBoothRequired: boolean | null;
  isRainProof: boolean | null;
  streetAddress: string;
  type: `${LocationType}`;
}

export interface Location extends LocationBase {
  /**
   * Coordinates as array in the form `[longitude, latitude]`.
   * Projection used is WGS 84 Web Mercator (EPSG:3857).
   * May be null, if no coordinates are available
   */
  coordinates: LegacyGeoCoordinates | null;
  isActive: boolean;
  /** URL to the image, otherwise `null` */
  image: string | null;
  // decimal formatted as string #.##
  pricePerDay: string | null;
}

export interface LocationDetail extends Location {
  customerId: EntityRef<Customer>;
  // latestPermit: EntityRef<LocationPermit> | null;
  // comments: EntityCollectionRef<LocationComment>[];
  latestPermit: LocationPermit | null;
  comments: LocationComment[];
}

export type LocationMinimalEntity = EntityKind<LocationMinimal, 'minimal'>;
export type LocationDefaultEntity = EntityKind<Location, 'default'>;
export type LocationDetailEntity = EntityKind<
  LocationDetail,
  'detail',
  'latestPermit',
  'comments'
>;

export type LocationEntity =
  | LocationMinimalEntity
  | LocationDefaultEntity
  | LocationDetailEntity;

const distinctKind = distinctByLiteralProperty<LocationEntity>()('kind');

const _isLocationDefaultKind = distinctKind<LocationDefaultEntity>('default');
const isLocationDetailKind = distinctKind<LocationDetailEntity>('detail');
const _isLocationMinimalKind = distinctKind<LocationMinimalEntity>('minimal');

export function isLocationDefaultKind(
  entity: LocationEntity,
): entity is LocationDetailEntity {
  return _isLocationDefaultKind(entity) || isLocationDetailKind(entity);
}

export function isLocationMinimalKind(
  entity: LocationEntity,
): entity is LocationDetailEntity {
  return (
    _isLocationMinimalKind(entity) ||
    isLocationDefaultKind(entity) ||
    isLocationDetailKind(entity)
  );
}

interface LocationDetailNormalized {
  result: LocationDetailEntity;
  entities: {
    comments: LocationComment[];
    latestPermit: LocationPermit | null;
  };
}

export function normalizeLocationDetail(
  location: LocationDetail,
): LocationDetailNormalized {
  const comments = [...location.comments];
  const commentIds = comments.map((c) => c.id);
  const latestPermit = location.latestPermit;
  const latestPermitId = location.latestPermit?.id ?? null;

  return {
    result: {
      ...location,
      kind: 'detail',
      comments: commentIds,
      latestPermit: latestPermitId,
    },
    entities: {
      comments,
      latestPermit,
    },
  };
}
