import { Injectable, OnDestroy } from '@angular/core';
import type { CalendarWeek, TimeSpanInput } from '@fmnts/core/chronos';
import moment from 'moment';
import {
  BehaviorSubject,
  combineLatest,
  OperatorFunction,
  Subscription,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { LocaleService } from './locale.service';

@Injectable()
export class DateService implements OnDestroy {
  /**
   * Use to dynamically update the date format
   */
  private _dateFormat$: BehaviorSubject<string> = new BehaviorSubject('');

  /**
   * Emits with the localized date format for inputs, which ensures to
   * use the padded versions of formats, so e.g. the format `D-M-YYYY`
   * becomes `DD-MM-YYYY`.
   */
  public dateInputFormat$ = this._dateFormat$.pipe(
    map((format) =>
      format
        .replace(/[D]+/i, 'DD')
        .replace(/[M]+/i, 'MM')
        .replace(/[Y]+/i, 'YYYY'),
    ),
  );

  private subscription = new Subscription();

  /**
   * Locale for moment.js.
   * Initialized via the locale service, so it will always have a value.
   */
  private _locale!: string;

  constructor(private localeService: LocaleService) {
    this.subscription.add(
      this.localeService.locale$.subscribe((locale) =>
        this.updateMoment(locale),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Updates the locale internally maintained by moment.js
   *
   * @param locale The locale to use for moment
   */
  private updateMoment(locale: string) {
    this._locale = locale.replace('_', '-');
    moment.locale(this._locale);

    this._dateFormat$.next(moment.localeData().longDateFormat('L'));
  }

  /**
   * Using this ensures that the returned moment object
   * uses the correct locale.
   *
   * @returns
   * A new moment object
   */
  public moment(
    input: moment.MomentInput,
    format?: moment.MomentFormatSpecification,
    strict?: boolean,
  ): moment.Moment {
    return moment(input, format, strict).locale(this._locale);
  }

  /**
   * @returns
   * Creates a localized moment object from each value
   * emitted by the source Observable and emits them.
   */
  public moment$(): OperatorFunction<moment.MomentInput, moment.Moment> {
    return (obs$) =>
      combineLatest([obs$, this.localeService.locale$]).pipe(
        map(([s]) => this.moment(s)),
      );
  }

  /**
   * @param date Date that's inside the week to construct
   *
   * @returns
   * An array of all weekdays for the week of the given date.
   */
  public weekdays(date: moment.MomentInput): moment.Moment[] {
    const d = this.moment(date);
    return [0, 1, 2, 3, 4, 5, 6].map((day) => d.clone().weekday(day));
  }

  /**
   * @param date Date which lies inside the calendar week to construct
   *
   * @returns
   * The calendar week for a given date.
   */
  public calendarWeek(date: moment.MomentInput): CalendarWeek {
    const week = this.moment(date);
    return {
      weekNumber: week.week(),
      days: this.weekdays(week),
    };
  }

  /**
   * @param range Date range.
   *
   * @returns
   * An Array of all calendar weeks within the given range, using the current
   * locale.
   */
  public calendarWeeksForRange(range: TimeSpanInput): CalendarWeek[] {
    const [startInput, endInput] = range;

    const start = this.moment(startInput);
    const end = this.moment(endInput);

    const calendarWeeks: CalendarWeek[] = [];
    const week = start.weekday(0);
    while (week.isBefore(end, 'day')) {
      calendarWeeks.push(this.calendarWeek(week));
      week.add(1, 'week');
    }

    return calendarWeeks;
  }
}
