import { GuardFn, PredicateFn } from './common.type';

/**
 * This higher order function takes a typeguard function as an
 * argument and returns another typeguard function that is a
 * negation of the given typeguard.
 *
 * @param predicate Typeguard to be negated
 *
 * @returns
 * Negated typeguard
 */
export function not<TNot extends Input, Input>(
  predicate: GuardFn<TNot, Input>,
): <T>(u: T | TNot) => u is Exclude<T, TNot>;
/**
 * Takes a predicate function and returns a new function that
 * negates the result of the predicate function.
 *
 * @param predicate Predicate function
 */
export function not<T>(predicate: PredicateFn<T>): PredicateFn<T>;
export function not(predicate: PredicateFn<unknown>): PredicateFn<unknown> {
  return (u) => !predicate(u);
}

// -------------------------------------------------------
// or
// -------------------------------------------------------
/**
 * Builds a type guard function from the passed type guard functions
 * that returns `true` if any of the passed type guard functions is
 * fulfilled.
 */
export function or<T1 extends Input, T2 extends Input, Input>(
  p1: GuardFn<T1, Input>,
  p2: GuardFn<T2, Input>,
): GuardFn<T1 | T2, Input>;
export function or<T1 extends Input, T2 extends Input, T3 extends Input, Input>(
  p1: GuardFn<T1, Input>,
  p2: GuardFn<T2, Input>,
  p3: GuardFn<T3, Input>,
): GuardFn<T1 | T2 | T3, Input>;
export function or<
  T1 extends Input,
  T2 extends Input,
  T3 extends Input,
  T4 extends Input,
  Input,
>(
  p1: GuardFn<T1, Input>,
  p2: GuardFn<T2, Input>,
  p3: GuardFn<T3, Input>,
  p4: GuardFn<T4, Input>,
): GuardFn<T1 | T2 | T3 | T4, Input>;
/**
 * Builds an "OR" operator predicate function from the passed predicate
 * functions that returns true if a value fulfills any of the functions.
 */
export function or<T>(...predicates: PredicateFn<T>[]): PredicateFn<T>;
export function or(
  ...predicates: PredicateFn<unknown>[]
): PredicateFn<unknown> {
  return (u) => predicates.some((p) => p(u));
}

/**
 * Builds a type guard function from the passed type guard functions
 * that returns `true` if all of the passed type guard functions is
 * fulfilled.
 */
export function and<T1 extends Input, T2 extends Input, Input>(
  p1: GuardFn<T1, Input>,
  p2: GuardFn<T2, Input>,
): GuardFn<T1 & T2, Input>;
export function and<
  T1 extends Input,
  T2 extends Input,
  T3 extends Input,
  Input,
>(
  p1: GuardFn<T1, Input>,
  p2: GuardFn<T2, Input>,
  p3: GuardFn<T3, Input>,
): GuardFn<T1 & T2 & T3, Input>;
export function and<
  T1 extends Input,
  T2 extends Input,
  T3 extends Input,
  T4 extends Input,
  Input,
>(
  p1: GuardFn<T1, Input>,
  p2: GuardFn<T2, Input>,
  p3: GuardFn<T3, Input>,
  p4: GuardFn<T4, Input>,
): GuardFn<T1 & T2 & T3 & T4, Input>;
/**
 * Builds an "AND" operator predicate function from the passed predicate
 * functions that returns true if a value fulfills all of the functions.
 */
export function and<T>(...predicates: PredicateFn<T>[]): PredicateFn<T>;
export function and(
  ...predicates: PredicateFn<unknown>[]
): PredicateFn<unknown> {
  return (u) => predicates.every((p) => p(u));
}
