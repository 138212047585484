import {
  Injectable,
  Renderer2,
  RendererFactory2,
  Inject,
  SecurityContext,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CustomStyleService {
  private renderer: Renderer2;
  private styleElement: HTMLStyleElement | null = null;

  constructor(
    private domSanitizer: DomSanitizer,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * create a global <style> tag in the DOM
   * @param style the CSS rules to set globally
   */
  setGlobalStyle(style: string): void {
    if (!style) {
      return;
    }
    // clear out the previous global style in case there was one present
    this.removeGlobalStyle();

    const sanitizedStyle = this.domSanitizer.sanitize(
      SecurityContext.STYLE,
      style,
    );
    if (!sanitizedStyle) {
      return;
    }

    // Append new style content
    this.styleElement = this.renderer.createElement(
      'style',
    ) as HTMLStyleElement;
    this.styleElement.appendChild(this.renderer.createText(sanitizedStyle));
    this.document.head.appendChild(this.styleElement);
  }

  /**
   * remove the global <style> tag with our custom CSS from the DOM
   */
  removeGlobalStyle(): void {
    if (this.styleElement) {
      this.styleElement.remove();
      this.styleElement = null;
    }
  }
}
