import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { DateService } from './date.service';
import { LocaleService } from './locale.service';
import { TranslationService } from './translation.service';
import type { TranslationHash } from './translation.types';

interface LocalizationModuleConfig {
  /**
   * Set of translations
   */
  translations: TranslationHash;

  /**
   * Set of languages, should be used in that order
   */
  order: string[];
}

/**
 * Module for localization (l10n).
 * Use this module if you want to provide the localization
 * to the modules that use internationalization.
 */
@NgModule({})
export class L10nModule {
  /**
   * Use this in your application root module to provide the
   * localization.
   *
   * @param config Configuration object
   * @returns
   * The new `NgModule`
   *
   * @example
   * @NgModule({
   *   imports: [LocalizationModule.forRoot({...})]
   * })
   * class AppModule {}
   */
  static forRoot(
    config: LocalizationModuleConfig,
  ): ModuleWithProviders<L10nModule> {
    return {
      ngModule: L10nModule,
      providers: [
        {
          provide: TranslationService,
          useFactory: () =>
            setupTranslationService(config.translations, config.order),
        },
        LocaleService,
        DateService,
      ],
    };
  }

  /**
   * Use this in your applications modules to provide the
   * localization. Requires **forRoot()** to be initialized first
   *
   * The child localization, can decouple from its parent in the
   * following ways:
   * - provide translations for a feature module only
   * - choose order in which languages should be shown
   *
   * @param config Configuration object
   * @returns
   * The new `NgModule`
   *
   * @example
   * @NgModule({
   *   imports: [LocalizationModule.forChild({...})]
   * })
   * class AppModule {}
   */
  static forChild(
    config: LocalizationModuleConfig,
  ): ModuleWithProviders<L10nModule> {
    return {
      ngModule: L10nModule,
      providers: [
        {
          provide: TranslationService,
          useFactory: () =>
            setupTranslationServiceForChild(config.translations, config.order),
        },
      ],
    };
  }

  constructor(private dateService: DateService) {}
}

/**
 * Sets up `TranslationService` for root
 * no parent `TranslationService` is set
 */
function setupTranslationService(
  translations: TranslationHash,
  order: string[],
) {
  const translate = new TranslationService(null);
  translate.setTranslations(translations);
  translate.setLanguageOrder(order);

  return translate;
}

/**
 * Sets up `TranslationService` for child
 * will inject the parent `TranslationService`
 */
function setupTranslationServiceForChild(
  translations: TranslationHash,
  order: string[],
) {
  // The InjectFlag `Optional` is explicitly left out, because children
  // should always be initialize with a parent
  const translate = new TranslationService(
    inject(TranslationService, { skipSelf: true }),
  );
  translate.setTranslations(translations);
  translate.setLanguageOrder(order);

  return translate;
}
