import { Translatable } from '@fmnts/api/shared';

export interface ICompliment {
  value: string;
  label: Translatable<string> | string;
  image: string;
}

export class Compliment implements ICompliment {
  value: string;
  label: Translatable<string> | string;
  image: string;

  constructor(json: Readonly<ICompliment>) {
    this.value = json.value;
    this.label = json.label;
    this.image = json.image;
  }
}
