/**
 * @returns
 * A function that can generate a type guard
 * for a type `T` by passing a property name
 * of `T`.
 *
 * @example
 * interface Species { species: string }
 * interface Cat extends Species {
 *  species: 'cat'
 * }
 * interface Dog extends Species {
 *  species: 'dog'
 * }
 *
 * // Creates a distinctor for interface `Species`
 * // by using its key `species`
 * const distinctSpecies =
 *  distinctByLiteralProperty<Species>()('species');
 *
 * // Create type guards for types from literals
 * const isDog = distinctSpecies<Dog>('dog');
 * const isCat = distinctSpecies<Cat>('cat');
 */
export function distinctByLiteralProperty<T>() {
  return <K extends keyof T>(key: K) =>
    <R extends T>(value: R[K]) =>
    (o: T): o is R =>
      o[key] === value;
}
