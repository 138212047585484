import { isRecord, isString } from '@fmnts/core';

import { InlineTranslateable } from './translation.types';

/**
 * Type guard that checks if something is of type {@link InlineTranslateable}.
 *
 * @param u Unknown value
 * @returns
 */
export function isInlineTranslatable(u: unknown): u is InlineTranslateable {
  return isString(u) || (isRecord(u) && !Array.isArray(u));
}
