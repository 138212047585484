import auth from './auth.lt.json';
import cockpit from './cockpit.lt.json';
import common from './common.lt.json';
import next from './next.lt.json';
import verify from './verify.lt.json';

export default {
  ...auth,
  ...cockpit,
  ...common,
  ...next,
  ...verify,
};
