import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LocaleService {
  /**
   * Fallback
   */
  public readonly fallbackLocale = 'en';
  /**
   * Observable
   */
  public readonly locale$: BehaviorSubject<string>;

  constructor() {
    this.locale$ = new BehaviorSubject(this.fallbackLocale);
  }

  /**
   * Set locale globally
   *
   * @param locale
   * The locale consists of `[language[_regionCode]]`,
   * where language can be used for translations, while regionCode is used
   * for special formatting for dates, currencies, ...
   */
  public setLocale(locale: string): void {
    this.locale$.next(locale);
  }
}
