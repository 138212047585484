import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

/**
 * A service that can be used to get and set the title of a current HTML document.
 */
@Injectable()
export class TitleService {
  private suffix = 'Formunauts';
  /** Separator that is used to join path segments */
  private readonly separator = ' · ';

  constructor(private ngTitleService: Title) {}

  /**
   * Sets the
   *
   * @param suffix Suffix that should be append when setting the title
   */
  public setSuffix(suffix: string = 'Formunauts'): void {
    this.suffix = suffix;
  }

  /**
   * Set the title of the current HTML document.
   * The current suffix is append to the title.
   *
   * @param title Title to set on the HTML
   */
  public setTitle(title?: string): void {
    this.setTitlePath(title ? [title] : []);
  }

  /**
   * Set the title of the current HTML document by combinig the
   * given `pathSegments` using the default separator and with the
   * configured suffix.
   *
   * @param pathSegments Segments that should be combined for the title
   */
  public setTitlePath(pathSegments: string[]): void {
    const newTitle = [...pathSegments, this.suffix].join(this.separator);
    this.ngTitleService.setTitle(newTitle);
  }

  /**
   * Get the title of the current HTML document.
   *
   * @returns
   * Title of the current HTML document without `suffix`.
   */
  public getTitle(): string {
    return this.ngTitleService.getTitle().replace(this.suffix, '');
  }
}
