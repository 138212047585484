import { NgModule } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FmntsIconsModule } from './icons.module';

/**
 * Provider that loads the default icon set for formunaut applications.
 *
 * You can use this in your app module to load the default icon set.
 *
 * @example
 * ```ts
 *  @NgModule({
 *    imports: [
 *      ...
 *      FmntsDefaultIconsLib,
 *    ]
 *  })
 *  export class AppModule { ... }
 * ```
 */
@NgModule({
  imports: [FmntsIconsModule],
  exports: [FmntsIconsModule],
})
export class FmntsDefaultIconsLib {
  constructor(library: FaIconLibrary) {}
}
