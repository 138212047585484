import { distinctByLiteralProperty, IPage } from '@fmnts/core';
import { Duration } from '@fmnts/core/chronos';
import { InlineTranslateable } from '@fmnts/i18n';
import { MetricDisplayType, MetricType } from './domain-enums';
import { EntityId } from './entity';

export type MetricsId = EntityMetrics['id'];
export type MetricsWidgetSlug = MetricsWidgetBase['slug'];

export interface AverageMetric {
  /** The count of elements over which the average was aggregated. */
  count: number;
  /** The calculated average. */
  average: number;
}

interface KeyValueMetric {
  value: number;
  key: InlineTranslateable;
}
export type KeyValueListMetric = KeyValueMetric[];

export interface MetricsWidgetBase<T = unknown> {
  /** Slug that identifies the metric. */
  slug: string;
  /** Describes the type of the metric. */
  metricType: `${MetricType}`;
  /** How the metric should be displayed. */
  displayType: `${MetricDisplayType}`;
  /** Title to show. */
  title: InlineTranslateable;
  /** Value for the current period. */
  value: T;
  /** Whether this metric can be used for sorting. */
  isSortable?: boolean;
  /** Custom CSS classes. */
  cssClasses?: string;
}

/**
 * A data structure that a metrics widget for each metric
 * by the slug of that same metric.
 */
export interface MetricWidgetsBySlug<T> {
  [slug: MetricsWidgetSlug]: T;
}

/**
 * A data structure that a metrics widget for each metric
 * by the slug of that same metric.
 */
export interface MetricWidgetMap<
  T extends Record<string, MetricsWidgetBase<unknown>>,
> {
  /** Contains an ordered list of slugs. */
  slugs: MetricsWidgetSlug[];
  /**
   * Contains the metrics.
   */
  bySlug: T;
}

/**
 * Contains metrics for a singly domain entity.
 */
export interface EntityMetrics {
  /** ID of the entity. */
  id: EntityId;
  /** Display name of the entity. */
  name: string;
  /** Image of the resource */
  image: string | null;
  /** Widgets per slug. */
  widgets: MetricWidgetMap<MetricWidgetsBySlug<MetricsWidget>>;
}

/**
 * A metrics page contains a list of results.
 */
export interface MetricsPage<
  T,
  TMetricTotals extends MetricWidgetsBySlug<
    MetricsWidgetBase<unknown>
  > = MetricWidgetsBySlug<MetricsWidget>,
> extends IPage<T> {
  /**
   * Accumulated totals for the widgets.
   * May be `undefined` in cases where totals don't make sense,
   * e.g. when fetching metrics for a single entity.
   */
  totals: MetricWidgetMap<TMetricTotals>;
}

export interface AverageMetricsWidget extends MetricsWidgetBase<AverageMetric> {
  metricType: `${MetricType.Average}`;
}
export interface DurationMetricsWidget extends MetricsWidgetBase<Duration> {
  metricType: `${MetricType.Duration}`;
}
export interface NumericMetricsWidget extends MetricsWidgetBase<number> {
  metricType: `${MetricType.Numeric}`;
}
export interface KeyValueListMetricsWidget
  extends MetricsWidgetBase<KeyValueListMetric> {
  metricType: `${MetricType.KeyValueList}`;
}
export interface TextListMetricsWidget
  extends MetricsWidgetBase<InlineTranslateable[]> {
  metricType: `${MetricType.TextList}`;
}

// Key Value List metric
interface MetricsWidgetBarChartHorizontal extends KeyValueListMetricsWidget {
  displayType: `${MetricDisplayType.BarChartHorizontal}`;
}
interface MetricsWidgetBarChartVertical extends KeyValueListMetricsWidget {
  displayType: `${MetricDisplayType.BarChartVertical}`;
}
interface MetricsWidgetPieChart extends KeyValueListMetricsWidget {
  displayType: `${MetricDisplayType.PieChart}`;
}
interface MetricsWidgetTextValueList extends KeyValueListMetricsWidget {
  displayType: `${MetricDisplayType.TextList}`;
}

// Duration Metric
interface MetricsWidgetDuration extends DurationMetricsWidget {
  displayType: `${MetricDisplayType.Duration}`;
}

// Numeric Metric
interface MetricsWidgetMetric extends NumericMetricsWidget {
  displayType: `${MetricDisplayType.Metric}`;
}
interface MetricsWidgetMoney extends NumericMetricsWidget {
  displayType: `${MetricDisplayType.Money}`;
}
interface MetricsWidgetPercentage extends NumericMetricsWidget {
  displayType: `${MetricDisplayType.Percentage}`;
}

// Average Metric

interface MetricsWidgetAverageMetric extends AverageMetricsWidget {
  displayType: `${MetricDisplayType.Metric}`;
}
interface MetricsWidgetAverageMoney extends AverageMetricsWidget {
  displayType: `${MetricDisplayType.Money}`;
}
interface MetricsWidgetAveragePercentage extends AverageMetricsWidget {
  displayType: `${MetricDisplayType.Percentage}`;
}
interface MetricsWidgetAverageRating extends AverageMetricsWidget {
  displayType: `${MetricDisplayType.Rating}`;
}

// Text List
interface MetricsWidgetTextList extends TextListMetricsWidget {
  displayType: `${MetricDisplayType.TextList}`;
}

export type MetricsWidget =
  | MetricsWidgetBarChartHorizontal
  | MetricsWidgetBarChartVertical
  | MetricsWidgetPieChart
  | MetricsWidgetTextValueList
  | MetricsWidgetDuration
  | MetricsWidgetMetric
  | MetricsWidgetMoney
  | MetricsWidgetPercentage
  | MetricsWidgetPieChart
  | MetricsWidgetAverageMetric
  | MetricsWidgetAverageMoney
  | MetricsWidgetAveragePercentage
  | MetricsWidgetAverageRating
  | MetricsWidgetTextList;

export const {
  [MetricDisplayType.BarChartHorizontal]: isMetricsWidgetBarChartHorizontal,
  [MetricDisplayType.BarChartVertical]: isMetricsWidgetBarChartVertical,
  [MetricDisplayType.Duration]: isMetricsWidgetDuration,
  [MetricDisplayType.Metric]: isMetricsWidgetMetric,
  [MetricDisplayType.Money]: isMetricsWidgetMoney,
  [MetricDisplayType.Percentage]: isMetricsWidgetPercentage,
  [MetricDisplayType.PieChart]: isMetricsWidgetPieChart,
  [MetricDisplayType.Rating]: isMetricsWidgetRating,
  [MetricDisplayType.TextList]: isMetricsWidgetTextList,
} = (function () {
  const distinctMetricsType =
    distinctByLiteralProperty<MetricsWidgetBase>()('displayType');

  return {
    [MetricDisplayType.BarChartHorizontal]:
      distinctMetricsType<MetricsWidgetBarChartHorizontal>(
        MetricDisplayType.BarChartHorizontal,
      ),
    [MetricDisplayType.BarChartVertical]:
      distinctMetricsType<MetricsWidgetBarChartVertical>(
        MetricDisplayType.BarChartVertical,
      ),
    /**
     * Type guard for Metric widgets of type `duration`
     */
    [MetricDisplayType.Duration]: distinctMetricsType<MetricsWidgetDuration>(
      MetricDisplayType.Duration,
    ),
    [MetricDisplayType.Metric]: distinctMetricsType<MetricsWidgetMetric>(
      MetricDisplayType.Metric,
    ),
    [MetricDisplayType.Money]: distinctMetricsType<MetricsWidgetMoney>(
      MetricDisplayType.Money,
    ),
    [MetricDisplayType.Percentage]:
      distinctMetricsType<MetricsWidgetPercentage>(
        MetricDisplayType.Percentage,
      ),
    [MetricDisplayType.PieChart]: distinctMetricsType<MetricsWidgetPieChart>(
      MetricDisplayType.PieChart,
    ),
    [MetricDisplayType.Rating]: distinctMetricsType<MetricsWidgetAverageRating>(
      MetricDisplayType.Rating,
    ),
    [MetricDisplayType.TextList]: distinctMetricsType<MetricsWidgetTextList>(
      MetricDisplayType.TextList,
    ),
  };
})();

export const {
  [MetricType.Average]: isAverageMetricsWidget,
  [MetricType.Duration]: isDurationMetricsWidget,
  [MetricType.KeyValueList]: isKeyValueListMetricsWidget,
  [MetricType.Numeric]: isNumericMetricsWidget,
  [MetricType.TextList]: isTextListMetricsWidget,
} = (function () {
  const distinctMetricsType =
    distinctByLiteralProperty<MetricsWidgetBase>()('metricType');

  return {
    [MetricType.Average]: distinctMetricsType<AverageMetricsWidget>(
      MetricType.Average,
    ),
    [MetricType.Duration]: distinctMetricsType<DurationMetricsWidget>(
      MetricType.Duration,
    ),
    [MetricType.Numeric]: distinctMetricsType<NumericMetricsWidget>(
      MetricType.Numeric,
    ),
    [MetricType.KeyValueList]: distinctMetricsType<KeyValueListMetricsWidget>(
      MetricType.KeyValueList,
    ),
    [MetricType.TextList]: distinctMetricsType<TextListMetricsWidget>(
      MetricType.TextList,
    ),
  };
})();
