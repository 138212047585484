/**
 * @param enumObj Enum object. Only works with non-const enums.
 *
 * @returns
 * A type guard function that returns true for a value if it
 * is part of the given `enumObj`.
 */
export function enumValueGuard<T extends string>(
  enumObj: Record<string, T>,
): (value: unknown) => value is T {
  const enumValues: unknown[] = Object.values(enumObj);
  return (value): value is T => enumValues.includes(value);
}
