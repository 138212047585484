import auth from './auth.fr.json';
import cockpit from './cockpit.fr.json';
import common from './common.fr.json';
import next from './next.fr.json';
import verify from './verify.fr.json';

export default {
  ...auth,
  ...cockpit,
  ...common,
  ...next,
  ...verify,
};
