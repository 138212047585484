import { isRecord } from './is-record.type-guard';

/**
 * Creates a new type guard function that checks if an object
 *  has a certain property and that this property has a certain type.
 */
export function hasProperty<T, K extends string>(
  key: K,
  is: (u: any) => u is T,
) {
  return (obj: unknown): obj is Record<K, T> =>
    isRecord(obj) && key in obj && is(obj[key]);
}
